.logo-container {
  width: 100%;
  text-align: center;
  
}
.mj-logo-letter{ color: aqua; 
font-size: 2rem;
font-family: 'Orbitron', sans-serif;
letter-spacing:2px;
text-decoration: none;
}
.p-logo-letter {
  width: 100%;
  text-align: center;
  font-family: 'Lobster';
  font-size: 1.3rem;
  color: rgb(193, 127, 35);
  text-decoration: none;
}
