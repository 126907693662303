.main-contact-left {
  width: 40%;
  display: flex;
  flex: 1;
}

.contact-title {
  font-size: 3rem;
  color: aliceblue;
  margin-bottom: 14rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  letter-spacing: 3px;
}
.me-from-contact {
  font-size: 2rem;
  letter-spacing: 2px;
  color: aqua;
  writing-mode: horizontal-tb;
  transform: rotate(180deg);
  border: 2px solid white;
  display: inline-block;
  padding: 0.5rem 0.2rem;
}
.map-container {
 text-align: center;
  position: relative;
}

.m-location {
  color: #c07f23;
  padding: 2px;
  font-family: gogoia;
  font-size: 2rem;
  font-family: 'Orbitron', sans-serif;
}

.mail {
  position: absolute;
  left: 28%;
  bottom: 75%;
  color: aqua;
  background-color: #1d1d1d;
  border: #c07f23 1px solid;
   background-color: #3b3a3a;
   padding: 0.2rem;
}

.main-Contact-right {
  width: 50%;
  display: flex;
  flex-direction: 1;
}
.form-contact {
  width: 80%;
}
.p-contact{
  text-align:center;
  margin-bottom: 1rem;
  color: #6e6e6e;
}
.contact-input{
  font-size: arial;
  display: block;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
  font-size: 1rem;
  background-color: #3b3a3a;
  border: none;
}



.contact-textarea {
  width: 100%;
  background-color: #3b3a3a;
  border: none;
  margin-bottom: 1rem;
  font-size: 1rem;
  padding: 0.5rem 0.2rem;
  font-family: arial;
}
.get-in-touch-button {
  width: 100%;
  display: inline-block;
  font-family: 'Orbitron', sans-serif;
  font-size: 1.5rem;
  background-color: #1d1d1d;
  padding: 1rem 3rem 1rem 3rem;
  border: 1px solid aqua;
  color: aqua;
}

.get-in-touch-button:hover {

  background-color: rgb(156, 104, 6);
}
