.ul-items{ margin-top:1rem;}

.li-items {
  margin: 0 auto;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  list-style: none;
  filter: blur(0.1);
  margin-bottom: 0.5rem;
  border-top: 1px solid white;
}

.li-items > a {
  font-family:"orbitron";
  letter-spacing:0.2px;
  font-size: 1.3em;
  font-weight: bold;
  width: 100%;
  color: white;
  text-decoration: none;
  padding: 1rem 0.3rem 1rem 0rem;
}
.ul-items li a:hover {
  color: aqua;
}
/*-------------------------------------------Queries---------------------------------------------*/

@media screen and (max-width:829px) {
  .ul-items{
  height: 100%;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
}
 .li-items {
  writing-mode:horizontal-tb;
  transform: none;
  list-style: none;
  filter: blur(0.1);
  margin-bottom: 0.5rem;
  border-bottom: 1px solid white;

}}

@media screen and (max-width:400px) {
  .ul-items{flex-wrap:wrap}
}