* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  font-size: 1rem;
}


@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lobster&family=Nanum+Myeongjo&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lobster&family=Nanum+Myeongjo&family=Roboto:ital,wght@0,100;1,100&display=swap');
body {
  padding-top: 2rem; 
  padding-bottom: 2rem;
  min-height: 100vh;
  background-image: url('../src/imgs/laptopjpg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

