.home-container-Left{
  font-family: 'Orbitron', sans-serif;
  width: 90%;
  color: rgb(110, 110, 110);
}
.greeting{
  font-size: 4em;
}

.greeting-exclamation {
  font-size: 1em;
  color: rgb(193, 127, 35);
}
.m-name-letter {
  font-size:1.2em;
  color: aqua;
}

.profession-text {
  display: inline;
  font-size: 4em;
}
.letwork-a {
  text-decoration: none;
  color: aliceblue;
  font-family: 'Orbitron', sans-serif;
}



.img-container {
  float:right;
  width: 300px;
  height: 300px;
  background-image: url('../../src/imgs/raascopy.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  animation: movimiento 35s infinite;
  overflow: hidden;
  border: 2px rgb(129, 82, 7) solid;
  border-radius: 100%;
  margin-bottom: 2rem;
  margin-right: 4rem;
}

.personal-image-home {
  object-fit: cover;
  display: inline-block;
}


.home-button {
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-size: 1.5em;
  background-color: #1d1d1d;
  padding: 1rem 2rem 1rem 2rem;
  border: 1px solid aqua;
  color: aqua;
}
button:hover {
  background-color: rgb(156, 104, 6);
}

/*---------------------------------------------------------------Media Queries---------------------------------*/
@media screen and (max-width:1320px) {
  .greeting{
    font-size: 4em;
  }
  
  .greeting-exclamation {
    font-size: 1em;
    color: rgb(193, 127, 35);
  }
  .m-name-letter {
    font-size:1.2em;
    color: aqua;
  }
  
  .profession-text {
    font-size: 3.5em;
  }}

  @media screen and (max-width:829px) {
    .greeting{
      font-size: 2em;
    }
    
    .greeting-exclamation {
      font-size: 1em;
      color: rgb(193, 127, 35);
    }
    .m-name-letter {
      font-size:1.2em;
      color: aqua;
    }
    
    .profession-text {
      display: inline;
      font-size: 2em;
    }
  
.home-container-right{ width: 100%;
  position:relative;
  right: 0;
  
}
.personal-image-home {
  position:none;
      object-fit: cover;
      display: inline-block;
      width: 200px;
      height: 200px;  
    }

.img-container {
  display: flex;
  height: 100%;
  width: 200px;
  background-image: url('../../src/imgs/raascopy.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  animation: movimiento 35s infinite;
  overflow: hidden;
  border: 2px rgb(129, 82, 7) solid;
  border-radius: 100%;
}

  }

  @media screen and (max-width:329px) {
    .profession-text{
      font-size: 1em;
    }}