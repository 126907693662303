/*  header*/
.header-container {
  padding: 1rem;
  min-width: 120px;
  border-radius: 10px 0px 0px  10px;
  display: flex;
  flex-direction: column;
  background-image: url('../../imgs/raascopy.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  animation: movimiento 30s infinite linear;
  box-shadow: 0px 10px 13px -3px #000000, 4px 3px 9px 4px rgba(132, 132, 132, 0);
}
@keyframes movimiento {
  from {
    background-position: bottom left;
  }
  to {
    background-position: top right;
  }
}
/*-------------------------------------------------------queries-----------------------------*/

@media screen and (max-width:829px) {

  .header-container {

    min-width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url('../../imgs/raascopy.jpg');
    background-repeat: no-repeat;
    background-position: center right;
    animation: movimiento 30s infinite linear;
    box-shadow: 0px 10px 13px -3px #000000, 4px 3px 9px 4px rgba(132, 132, 132, 0);
  }}

  