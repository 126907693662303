.container-main {
  width: 100%;
  display: flex;
  color: white;
 align-items: center;
 padding-left: 4rem;
}
/*-----------------------------------------------------Queries----------------------------------*/

@media screen and (max-width:1320px) {
  .container-main {

  }}
@media screen and (max-width:829px) {
  .container-main {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    height: 100%;
    width: 100%;
   padding-left: 0;
  
  }}