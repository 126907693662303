
.main-curriculum-Left {
    height: 100%;
    flex:1;
    border: solid blue 2px;
}
.download-me-title{
  font-size: 3rem;
  color: aliceblue;

  writing-mode: vertical-lr;
  transform: rotate(180deg);
  letter-spacing:3px;
}

.me-title-curriculum {
    font-size: 2rem;
    font-family: "Orbitron", sans-serif;
    letter-spacing:2px;
    color: aqua;
    writing-mode: horizontal-tb;
    transform: rotate(180deg);
    border: 2px solid white;
    display: inline-block;
    padding: 0.5rem 0.2rem; 
  }
.main-curriculum-right {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    flex-direction: column; 
    margin-left: 6rem;
}

.selector-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    border: #b77606 1px solid; 
    margin-bottom: 2.5rem;
    margin-left: 4rem;

}
.h1-lenguage-selector{ 
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 2rem;
    color: #6e6e6e;

}
.select-cv{
    font-size: 1rem;
    padding: 0.7rem 0;
    text-align: center;
    color: aqua;
    background-color: rgb(29,29,29);
    border: aqua solid 1px;
    font-family: 'Orbitron', sans-serif;
}

.curriculum-button{
    font-family: 'Orbitron', sans-serif;
    margin-top: 4rem;
    margin-bottom: 2rem;
    padding: 0.7rem 0;
    font-size: 1rem;
    background-color: #1d1d1d;
    border: 1px solid aqua;
    color: aqua;
}
