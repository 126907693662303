.lay-out {
  display: flex;
  height: 100%;
  width: 70%;
  font-family: 'Orbitron', sans-serif;
  border-radius: 10px;
  box-shadow: 0px 10px 13px -2px #000000, 4px 3px 9px 4px rgba(65, 64, 64, 0);
  background-color: #1d1d1d;
  margin: 0 auto;
  justify-items: center;
}
    @media screen and (max-width:1320px) {
      .lay-out {
        height: 100%;
        width: 100%;
        font-size: 1rem;
      }}

      @media screen and (max-width:820px) {
        .lay-out {
          height: 100%;
          width: 100vw;
          display: flex;
          flex-direction: column;
        }}
  
        @media screen and (max-width:500px) {
          .lay-out {
            height: 100%;
            width: 100%;
          }}
      
   