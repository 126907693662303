.main-about-Left {
  height: 100%;
   flex: 1;
  display: flex;
  margin-top: 10rem;
  color: aqua;
  font-weight: 600;
}

.about-me-title{
  font-size: 3rem;
  color: aliceblue;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  letter-spacing:3px;
}
.me-title {
  font-size: 2rem;
  font-family: "Orbitron", sans-serif;
  letter-spacing:2px;
  color: aqua;
  writing-mode: horizontal-tb;
  transform: rotate(180deg);
  border: 2px solid white;
  display: inline-block;
  padding: 0.5rem 0.2rem; 
}
.about-text-intro{ 
line-height: 1.6rem;
letter-spacing: 0.8px;
margin: 0rem 1rem 0 3rem;
font-size: 1rem;
color: rgb(110, 110, 110);
font-family: arial;
}

.main-About-right {
  display: flex;
  height: 100%;
  margin-top: 18rem;
  flex: 1;
;
  
}

.img-AboutContainer {
  margin-left: 2rem;
  width: 250px;
  height: 250px;
  background-image: url('../imgs/raascopy.jpg');
  background-repeat: no-repeat;
  background-position: center right;
  animation: movimiento 30s infinite linear;
  border: rgb(207, 134, 6) solid 1px;
}

.img-about {
  object-fit: cover;
  display: inline-block;
  position: relative;
  top: 1.8rem;
  left: 1.8rem
}